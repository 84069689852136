.breadcrumb_component-layout {
    display: flex;
    flex-direction: row;
    gap: 8px;
    p {
        font-family: var(--font-title);
        font-weight: 400;
        font-size: 1rem;
        color: var(--text);
        margin: 0px;
        padding: 0px;
    }
    .item_breadcrumb_component-layout {
        font-family: var(--font-title);
        font-weight: 400;
        font-size: 1rem;
        color: var(--text);
        margin: 0px;
        padding: 0px;
        &.hover-text {
            &:hover {
                color: var(--primary2);
                cursor: pointer;
            }
        }
    }
}