.smart_factory_plan--layout{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .smart_factory_plan--explication{
        padding: 40px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        align-items: center;
        background-color: transparent;
    }
    .smart_factory_plan--image{
        margin: -100px -50px -180px 0px;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .smart_factory_plan--content{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        h1, p{
            color: white;
            margin: 0px;
            padding: 0px;
        }
        &>h1{
            font-weight: bold;
        }
        &>p{
            font-size: 1.3rem;
        }
        .smart_factory_plan--button{
            background-color: #F8B133 !important;
            border-color: #F8B133 !important;
            border-radius: 8px;
            padding: 0.4rem 1.5rem;
            text-transform: none;
            margin-left: auto;
            margin-right: 20px;
        }
    }
    .smart_factory_plan--container-cards{
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border: 1px solid white;
        position: relative;
        z-index: 1;
        background: linear-gradient(180deg, rgba(30, 30, 30, 0.7) 0%, rgba(30, 30, 30, 0.15) 100%);
    }
    .smart_factory_plan--plan-card{
        background-color: transparent;
        &.active{
            background-color: #04194d;
            margin-top: -60px;
            margin-bottom: 60px;
            box-shadow: 0 0.4rem 1rem 0.1rem #F8B133;
        }
        p{
            color: white !important;
        }
        h2{
            color: #F8B133 !important;
        }
    }
    @media screen and (max-width: 1000px) {
        .smart_factory_plan--explication{
            grid-template-columns: 1fr;
            padding: 40px 0px;
        }
        .smart_factory_plan--image{
            margin: -100px 0px -50px 0px;
        }
        .smart_factory_plan--container-cards{
            display: grid;
            grid-template-columns: 100%;
        }
        .smart_factory_plan--plan-card{
            &.active{
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}