.home_page-layout {
    width: calc(100% - 30px);
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    .container-cards-categories {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-top: 40px;
    }
    .container-history-calendar {
        margin-top: 30px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
    @media screen and (max-width: 1300px) {
        .container-cards-categories {
            grid-template-columns: repeat(2, 50%);
            row-gap: 35px;
        }
    }
    @media screen and (max-width: 960px) {
        padding-bottom: 50px;
        .container-cards-categories {
            grid-template-columns: 100%;
            row-gap: 35px;
        }
        .container-history-calendar {
            grid-template-columns: 100%;
        }
    }
}