.color_picker-layout{
    display: flex;
    align-items: center;
    .container-color-field{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .square-color-picker{
        width: 40px;
        height: 36px;
        margin-top: 18px;
        border-radius: 8px;
        &:hover{
            cursor: pointer;
        }
    }
}