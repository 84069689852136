.payment_page-layout{
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 20px;
    .payment_page--container{
        padding: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        position: relative;
        .payment_page--card{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            .payment_page--title-underline{
                height: 5px;
                border-radius: 4px;
                background-color: var(--primary2);
                width: 50%;
            }
            &>div:last-of-type{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
        .payment_page--form{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            grid-auto-rows: 50px;
        }
        .payment_page--actions{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -28px;
            left: 0px;
            .payment_page--actions-buy_button{
                padding: 0.8rem 1.2rem;
            }
        }
    }
    .payment_page--information-cards{
        display: flex;
        flex-direction: column;
        gap: 30px;
        .payment_page--valor-card{
            background-color: var(--background);
            box-shadow: var(--shadow);
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1.5rem 1rem;
            border-radius: 16px;
            height: fit-content;
            &:first-of-type{
                margin-top: 100px;
            }
            &>div{
                display: flex;
                align-items: center;
                gap: 10px;
            }
            .payment_page--valor-number{
                margin-left: auto;
                color: var(--text2);
                font-size: 1.3rem;
            }
            img{
                width: 80%;
                margin: 0px auto;
                object-fit: cover;
            }
        }
    }
}