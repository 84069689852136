.share_module-layout{
  .icon-social-media-share{
    color: var(--primary);
  }
  .icon-share-module{
    font-size: 1.5rem;
    color: var(--text2);
    &:hover {
        cursor: pointer;
    }
  }
}
