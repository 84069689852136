.free_text_component-layout{
    $width-box: 150px;
    $height-box: 30px;
    $left-corner: $height-box;
    $right-corner: calc(#{$width-box} - #{$height-box});
    position: absolute;
    right: -32px;
    top: 28px;
    transform: rotate(45deg);
    height: $height-box;
    width: $width-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    -webkit-clip-path: polygon($left-corner 0%, $right-corner 0%, 100% 100%, 0% 100%);
    clip-path: polygon($left-corner 0%, $right-corner 0%, 100% 100%, 0% 100%);
    &>p{
        color: white;
        text-transform: uppercase;
        text-align: center;
    }
}