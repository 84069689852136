.modal_reserve_laboratory-layout{
    position: relative;
    overflow-y: auto;
    .close-modal-button{
        position: absolute;
        right: 0px;
        top: 0px;
        svg{
            font-size: 1.5rem;
            color: var(--text2);
            &:hover{
                cursor: pointer;
            }
        }
    }
    
    
}