.plan_card-layout{
    padding: 20px;
    border-radius: 26px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 10px;
    .container-price-plan{
        display: flex;
        gap: 20px;
        align-items: flex-end;
    }
    .text-plan-card{
        color: var(--text);
    }
    .container-benefist-plan{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .item-benefist-plan{
            display: grid;
            grid-template-columns: 30px 1fr;
            align-items: center;
            gap: 10px;
            .circle-check{
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background-color: rgba($color: #0C9CF2, $alpha: 0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    color: #0C9CF2;
                    font-size: 20px;
                }
            }
        }
    }
    .button-select-plan{
        background-color: rgba($color: #0C9CF2, $alpha: 0.3);
        color: #0C9CF2;
        padding: 0.8rem;
        margin-top: 30px;
    }
    &.active{
        padding: 30px 40px 40px 40px;
        background-color: var(--primary);
        box-shadow: 0 0.4rem 1rem 0.1rem var(--secondary);
        .text-plan-card{
            color: var(--background-login);
        }
        .container-benefist-plan{
            .item-benefist-plan{
                .circle-check{
                    background-color: rgba($color: #FFFFFF, $alpha: 0.3);
                    svg{
                        color: white;
                    }
                }
            }
        }
        .button-select-plan{
            background-color: var(--accent);
            color: black;
        }
    }
}