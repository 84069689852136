.custom_plan_page-layout{
    .custom_plan--information{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        margin-bottom: 10px;
        .custom_plan--plan{
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px 0px;
            .custom_plan--plan-title{
                font-weight: bold;
            }
        }
        &>div:last-of-type{
            margin-left: auto;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
        }
    }
    .custom_plan--table{
        max-height: 600px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 8px !important;
            background-color: var(--background2);
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}