.recommended_laboratory_card-layout {
    width: calc(100% - 15px);
    height: 270px;
    background-color: rgba(3, 19, 59, 0.6);
    margin: auto;
    border-radius: 30px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}