.modal_edit_reserve-layout{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    .item-name-field{
        display: flex;
        align-items: center;
        gap: 8px;
    }
}