.text_component-layout{
    margin: 0px;
    padding: 0px;
    font-family: var(--font-text);
    color: var(--text);
    &.h1{
      font-weight: 700;
      font-size: 2.5rem;
    }
    &.h2{
      font-weight: 700;
      font-size: 2rem;
    }
    &.h3{
      font-weight: 700;
      font-size: 1.5rem;
    }
    &.subtitle{
      font-weight: 700;
      font-size: 1.25rem;
    }
    &.button{
      font-weight: 700;
      font-size: 1rem;
    }
    &.caption{
      font-weight: 400;
      font-size: 0.875rem;
      color: var(--secondary-mode4);
      font-style: italic;
    }
    &.body{
      font-weight: 400;
      font-size: 1rem;
      font-family: var(--font-text);
      line-height: 21.82px;
    }
    &.text{
      font-weight: 400;
      font-size: 1rem;
      font-family: var(--font-text);
    }
    &.label{
      font-weight: 500;
      font-size: 0.875rem;
      font-family: var(--font-text);
    }
  }
  