.list_cataloger_items_page-layout{
    .container-list-items{
        box-shadow: var(--shadow);
        background-color: var(--background);
        border-radius: 30px;
        padding: 25px;
        width: 100%;
        .container-actions{
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 15px;
        }
    }
}