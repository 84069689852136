.push_button_module_dashboard-layout{
    width: 100%;
    height: 100%;
    $size-button: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    .container-icon-button{
        &:hover{
            cursor: pointer;
        }
        border-radius: 50%;
        width: $size-button;
        height: $size-button;
        background-color: #C4C4C4;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 4px rgba(244, 105, 96, 0.5);
        margin-top: auto;
        margin-bottom: auto;
        svg{
            font-size: calc(#{$size-button} - 0.5rem);
            color: #979797;
        }
        &.active{
            // background-color: var(--primary3);
            box-shadow: 0px 0px 4px rgba(12, 156, 242, 0.5);
            svg{
                font-size: calc(#{$size-button} - 1rem);
                color: var(--primary3);
            }
        }
        .active-icon{
            color: var(--primary3);
            font-size: calc(#{$size-button} - 0.5rem);
        }
    }
}