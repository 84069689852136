.laboratory_dashboard_form--layout{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    border: 1px solid var(--text);
    border-radius: 16px;
    padding: 20px;
    .dashboard_item--layout{
        display: grid;
        grid-template-columns: repeat(2, 1fr) 100px 80px;
        align-items: flex-end;
        gap: 20px;
        width: 100%;
        &>svg{
            color: var(--primary);
            font-size: 1.6rem;
            &:hover{
                cursor: pointer;
            }
        }
    }
}