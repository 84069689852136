.home_cataloger_page-layout{
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    .container-card-category,
    .container-card-item{
        background-color: var(--background);
        box-shadow: var(--shadow);
        padding: 25px;
        border-radius: 30px;
        width: 100%;
    }
    .container-card-category{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &>div{
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2, calc(50% - 8px));
            gap: 16px;
        }
    }
}