.smart_factory_our_clients--layout{
    border-radius: 16px;
    padding: 25px;
    box-shadow: -2px -2px white;
    background: linear-gradient(180deg, #03133B -42.59%, rgba(0, 0, 0, 0) -42.59%, rgba(3, 19, 59, 0.7) -42.58%, rgba(3, 19, 59, 0.5) 4.35%, rgba(151, 60, 68, 0.055) 99.99%, rgba(248, 177, 51, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 35px;
    h1, p{
        color: white;
        margin: 0px;
        padding: 0px;
    }
    &>h1{
        font-weight: bold;
    }
    &>p{
        font-size: 1.3rem;
        max-width: 1000px;
    }
    .smart_factory_our_clients--cards{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        margin-bottom: -60px;
    }
    .client_card{
        border-radius: 16px;
        background: linear-gradient(180deg, #0C9CF2 -42.59%, rgba(0, 0, 0, 0) -42.59%, #0C9CF2 -42.58%, rgba(12, 156, 242, 0.18) 4.35%, rgba(151, 60, 68, 0.055) 99.99%, rgba(12, 156, 242, 0) 100%);
        backdrop-filter: blur(14.0333px);
        padding: 20px;
        width: 100%;
        border: 1px solid #F8B133;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &>p{
            color: white;
            font-size: 1.2rem;
        }
        .client_card--info{
            display: flex;
            align-items: center;
            gap: 10px;
            .client_card--info-image{
                border-radius: 50%;
                width: 65px;
                height: 65px;
            }
            .client_card--info-name{
                &>p:last-of-type{
                    color: #0C9CF2;
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .smart_factory_our_clients--cards{
            grid-template-columns: 1fr;
        }
    }
}