.camera_module_dashboard-layout{
    width: 100%;
    height: 100%;
    .content-panel-camera{
        height: 100%;
    }
    .header-camera-container{
        border-radius: 30px 30px 0px 0px;
        padding: 20px 30px 40px 20px;
        background-color: #C2E4F8;
    }
    .container-iframe-camera{
        margin-top: -30px;
        height: calc(100% - 100px);
        background-color: var(--primary);
    }
    .footer-camera-container{
        border-radius: 0px 0px 30px 30px;
        padding: 20px 50px 20px 50px;
        background-color: var(--primary);
    }
    .list-cameras-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        .button-select-camera{
            font-size: 0.8rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
        }
    }
}