.dashboard_laboratory_page-layout{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    .container-published-info{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        gap: 10px;
        .copy-text-dashboard{
            width: 610px;
        }
    }
    .container-timer-header-dashboard{
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        .tool-bar-dashboard{
            height: fit-content;
        }
    }
    .contianer-chart-tool{
        // .container-children-module{
        //     overflow-y: initial;
        // }
    }
    .not-allowed-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
            width: 60vw;
            max-width: 700px;
            object-fit: cover;
        }
    }
}