.tool_bar_dashboard-layout{
    width: 100%;
    border-radius: 25px;
    background-color: var(--primary2);
    padding: 15px 35px;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    .icon-computer-menu{
        font-size: 2rem;
        color: white;
        &:hover{
            cursor: pointer;
        }
    }
    // ==========================================================
    // Item de toolbar
    // ==========================================================
    .item_tool_bar-layout{
        .container-content-item-tool-bar{
            width: calc(100% - 10px);
            margin-left: 5px;
            // border-right: 1px solid white;
            border: 1px solid white;
            padding: 4px 8px;
            border-radius: 8px;
            display: grid;
            grid-template-columns: 32px calc(100% - 40px);
            align-items: center;
            justify-content: center;
            gap: 8px;
            &:hover{
                cursor: pointer;
            }
            .icon-item-tool-bar{
                color: white;
                font-size: 1.5rem;
            }
            &>p{
                color: white;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &.active{
            .container-content-item-tool-bar{
                background-color: white;
                border-radius: 8px;
                .icon-item-tool-bar{
                    color: black;
                }
                &>p{
                    color: black;
                }
            }
        }
    }
}