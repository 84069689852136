.public_settings_reservation-layout{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .item-section{
        border-radius: 8px;
        border: 1px solid var(--text2);
        padding: 8px;
        .container-input-form{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            align-items: flex-end;
        }
    }
    .container-actions-settings{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
    // ======================================================
    // Item de menu para cada sección
    // ======================================================
    .item_public_config-layout{
        display: grid;
        grid-template-columns: 1fr 50px;
        gap: 10px;
        align-items: center;
    }
}