.profile_page-layout {
    .container-card-fields-profile {
        border-radius: 30px;
        padding: 15px;
        padding-bottom: 30px;
        background-color: var(--background);
        box-shadow: var(--shadow);
        .container-header-profile {
            height: 300px;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            .img-header {
                // width: calc(100% + 50px);
                width: 100%;
                height: 100%;
            }
            .img-profile-header {
                position: absolute;
                left: 40px;
                top: 150px;
                width: 300px;
                height: 300px;
                border-radius: 50%;
                object-fit: cover;
                border: 2px solid var(--accent);
            }
            // .container-profile-img-header {
            //     position: absolute;
            //     left: 40px;
            //     top: 150px;
            //     width: 300px;
            //     height: 300px;
            //     border-radius: 50%;
            //     .img-profile-header {
            //         width: 300px;
            //         height: 300px;
            //         border-radius: 50%;
            //         object-fit: cover;
            //         border: 2px solid var(--accent);
            //     }
            // }
            .container-actions-header {
                position: absolute;
                top: 25px;
                right: 50px;
                display: flex;
                gap: 20px;
                flex-direction: row;
                align-items: center;
                .btn-plan-selector {
                    border: 1px solid white;
                    color: white;
                    background-color: rgba($color: white, $alpha: 0.3);
                    font-family: var(--font-title);
                    font-weight: 400;
                    border-radius: 15px;
                    padding: 0.5rem 1.5rem;
                    font-size: 1.1rem;
                }
                .container-icon-language {
                    svg {
                        color: white;
                        font-size: 1.8rem;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .container-name-user {
            display: flex;
            gap: 25px;
            align-items: center;
            padding-left: 380px;
            padding-top: 20px;
            .btn-my-laboratories {
                border-radius: 25px;
                padding: 0.7rem 2.2rem;
                font-size: 1.1rem;
                text-transform: none;
            }
        }
        .location-text-component {
            display: flex;
            gap: 5px;
            align-items: center;
            svg {
                color: var(--primary2);
                font-size: 1.4rem;
            }
        }
        .container-text-movil-name {
            display: none;
        }
        .container-fields-list-profile {
            margin-top: 120px;
            padding-left: 200px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .field-editor-profile {
                display: grid;
                grid-template-columns: repeat(2, calc(45% - 100px)) 200px;
                gap: 5%;
                .title-field-editor {
                    font-size: 1.5rem;
                }
                &>div {
                    width: 350px;
                }
            }
        }
    }
    .container-recommended-cards {
        padding: 20px;
        margin-top: 20px;
        .container-cards-categories {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-items: center;
            justify-content: center;
            background-color: transparent;
            margin-top: 20px;
        }
        @media screen and (max-width: 1300px) {
            .container-cards-categories {
                grid-template-columns: repeat(2, 50%);
                row-gap: 35px;
            }
        }
        @media screen and (max-width: 960px) {
            padding: 0px;
            padding-bottom: 50px;
            .container-cards-categories {
                // grid-template-columns: 100%;
                row-gap: 35px;
                &>div {
                    height: 170px;
                }
            }
        }
    }
    @media screen and (max-width: 960px) {
        .container-card-fields-profile {
            padding: 10px;
            .container-header-profile {
                height: 140px;
                .img-profile-header {
                    top: 80px;
                    width: 120px;
                    height: 120px;
                    left: 20px;
                }
                .container-actions-header {
                    right: 10px;
                    top: 15px;
                    gap: 10px;
                    .btn-plan-selector {
                        padding: 0.4rem 0.8rem;
                        font-size: 0.9rem;
                    }
                }
            }
            .container-name-user {
                padding-left: 0px;
                padding-top: 10px;
                .btn-my-laboratories {
                    padding: 0.4rem 0.7rem;
                    font-size: 0.9rem;
                    margin-left: auto;
                }
            }
            .container-text-movil-name {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 20px;
                &>p:first-of-type {
                    font-weight: 600;
                    font-size: 1.1rem;
                }
                .location-text-component {
                    margin-left: auto;
                    font-size: 0.8rem;
                    color: var(--text2);
                }
            }
            .container-fields-list-profile {
                padding-left: 0px;
                margin-top: 20px;
                gap: 15px;
                .field-editor-profile {
                    display: grid;
                    grid-template-columns: repeat(2, 37.5%) 25%;
                    gap: 0px;
                    .title-field-editor {
                        font-size: 0.9rem;
                        font-weight: 600;
                    }
                    .text-field-editor {
                        font-size: 0.8rem;
                    }
                    .container-cancel-save-text {
                        gap: 5px;
                    }
                    .edit-button-text {
                        font-size: 0.7rem;
                    }
                    &>div {
                        width: 100%;
                    }
                }
            }
        }
    }
}