.code_editor_response-layout{
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0px;
    border: 1px dotted var(--text2);
    border-radius: 8px;
    padding: 5px;
    &::-webkit-scrollbar{
        width: 8px !important;
        background-color: var(--background2);
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .container-message{
        padding-left: 30px;
    }
}