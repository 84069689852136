.smart_factory_platforms--layout{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    h1, p{
        color: white;
        margin: 0px;
        padding: 0px;
    }
    &>h1{
        font-weight: bold;
    }
    &>p{
        font-size: 1.3rem;
        max-width: 1000px;
    }
    .smart_factory_platforms--list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        .smart_factory_platforms--card{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
    @media screen and (max-width: 1000px) {
        padding: 0px 20px;
    }
}