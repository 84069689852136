.laboratories_page-layout {
    display: grid;
    grid-template-columns: 250px calc(100% - 270px);
    gap: 20px;
    .col-filters {
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-shadow: var(--shadow);
        height: calc(100vh - 120px);
        overflow-y: auto;
        border-radius: 30px;
        padding: 25px 20px;
    }
    .col-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: calc(100vh - 120px);
        overflow-y: auto;
        padding-bottom: 30px;
        padding-right: 20px;
        .carousel-laboratories {
            .item-carousel-home {
                img {
                    height: 300px;
                }
            }
        }
        .container-filter-grid {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            padding: 5px 10px 5px 0px;
            .free-premium-buttons {
                display: flex;
                height: 50px;
                .btn-custom {
                    border-radius: 30px;
                    &:first-of-type {
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                    &:last-of-type {
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }
                    &.disabled {
                        background-color: rgba($color: #000000, $alpha: 0.3);
                        color: var(--text);
                    }
                }
            }
            .grid-icon {
                padding: 5px;
                border-radius: 4px;
                &.active {
                    background-color: rgba($color: #000000, $alpha: 0.4);
                }
                &:hover {
                    cursor: pointer;
                }
                svg {
                    font-size: 1.5rem;
                    color: var(--text);
                }
            }
        }
        .container-list-cards-preview {
            display: grid;
            grid-template-columns: repeat(3, calc(33% - 7px));
            align-items: center;
            justify-content: center;
            gap: 14px;
            &.list {
                grid-template-columns: 100%;
            }
        }
    }
    @media screen and (max-width: 1600px){
        .col-container{
            padding: 0px;
            .container-list-cards-preview{
                grid-template-columns: repeat(2, calc(50% - 5px));
                padding-bottom: 40px;
                gap: 10px;
            }
        }
    }
    @media screen and (max-width: 960px) {
        grid-template-columns: 100%;
        .col-container{
            padding: 0px;
            .container-list-cards-preview{
                grid-template-columns: 100%;
                padding-bottom: 40px;
                gap: 10px;
            }
            .container-filter-grid {
                .free-premium-buttons{
                    height: 36px;
                    button{
                        padding: 0.6rem;
                    }
                }
            }
        }
    }
}