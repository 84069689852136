.footer_landing-layout{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    margin-top: 20px;
    padding-bottom: 30px;
    width: 100%;
    .container-logos{
        width: 100%;
        display: flex;
        gap: 5%;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width: 1300px) {
        .container-logos{
            flex-direction: column;
        }
    }
}