.card_calendar_home-layout {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 30px;
    box-shadow: var(--shadow);
    background-color: var(--background-login);
    max-height: 600px;
    min-height: 600px;
    position: relative;
    .header-card {
        margin-bottom: 10px;
        .header-card-title {
            font-weight: 700;
            font-size: 1.3rem;
        }
        .header-card-subtitle {
            font-size: 0.9rem;
            color: vat(--text2);
        }
    }
    .container-calendar-home {
        padding: 10px;
    }
}