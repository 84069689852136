.notifications_list-layout{
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .header-card-list-notifications{
        display: flex;
        align-items: center;
        gap: 8px;
        .icon-setting-notifications{
            color: #545454;
            font-size: 1.5rem;
            margin-left: auto;
        }
    }
    .container-list-notifications{
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 300px;
        overflow-y: auto;
    }
    .container-actions-notifications{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}