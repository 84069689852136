.copy_text_component-layout{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    code{
        max-width: calc(100% - 2rem);
        overflow: auto;
        max-height: 1rem;
    }
    svg{
        font-size: 1.2rem;
        color: var(--text2);
        margin-left: auto;
        &:hover{
            cursor: pointer;
        }
        &.copied{
            color: var(--accent);
        }
    }
}