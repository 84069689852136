.finish_laboratory_page-layout{
    padding: 20px;
    .content-page-feedback{
        border-radius: 20px;
        background-color: var(--background3);
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;
        .icon-finish-lab{
            width: 70%;
        }
        .input-area-finish{
            width: 500px;
        }
    }
    @media screen and (max-width: 960px) {
        .content-page-feedback{
            .input-area-finish{
                width: 90%;
            }
        }
    }
}