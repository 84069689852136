.help_steps-component {}

.float-help-button {
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    color: white;
    border-radius: 50px;
    text-align: center;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
    padding: 0px;
    &:hover {
        color: white;
    }
    .icon-help {
        font-size: 1.6rem;
    }
    @media screen and (max-width: 960px) {
        display: none !important;
    }
}

.btn_help-sidebar {
    background-color: transparent;
    width: 40px;
    height: 40px;
    box-shadow: none;
    padding: 0px;
    color: var(--gray-600);
    text-align: center;
    .icon-help {
        font-size: 1.9rem;
    }
}

.fixed_help-position {
    position: fixed;
    z-index: 2000;
}

// ====== Estilos personalizados ==========
.__floater__open {
    z-index: 2000 !important;
}