.favorites_page-layout{
    .favorites-module-in-page{
        display: grid;
        grid-template-columns: repeat(4, calc(25% - 12px));
        gap: 16px;
    }
    @media screen and (max-width: 1400px) {
        .favorites-module-in-page{
            grid-template-columns: repeat(3, calc(33.33% - 10.67px));
        }
    }
    @media screen and (max-width: 1500px) {
        .favorites-module-in-page{
            grid-template-columns: repeat(3, calc(33.33% - 10.67px));
        }
    }
    @media screen and (max-width: 1200px) {
        .favorites-module-in-page{
            grid-template-columns: repeat(2, calc(50% - 8px));
        }
    }
    @media screen and (max-width: 700px) {
        .favorites-module-in-page{
            grid-template-columns: 100%;
        }
    }
}