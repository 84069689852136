.contact_form_module-layout{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--background-login);
    width: fit-content;
    padding: 20px;
    border-radius: 16px;
    .container-general-fields{
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 8px));
        gap: 16px;
        width: 100%;
    }
}