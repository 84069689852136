.smart_factory_info_card--layout{
    width: 100%;
    height: 300px;
    border: 1px solid white;
    border-radius: 8px;
    position: relative;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    .smart_factory_info_card--info{
        border-radius: 8px;
        padding: 1rem;
        color: white;
        max-width: 50%;
        position: relative;
        .smart_factory_info_card--blur{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            filter: blur(4px);
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 0;
        }
        &>p, &>h3 {
            margin: 0px;
            padding: 0px;
            z-index: 1;
            position: relative;
        }
        &>h3{
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1000px) {
        .smart_factory_info_card--info{
            max-width: 100%;
        }
    }
}