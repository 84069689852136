.home_carousel-layout {
    .item-carousel-home {
        position: relative;
        img {
            border-radius: 30px;
            height: 400px;
            width: 100%;
            object-fit: cover;
        }
        .container-info-banner{
            background-color: rgba(0,0,0,0.2);
            border-radius: 30px;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            padding: 50px 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .button-banner-action{
                margin-top: 50px;
                background-color: white;
                color: var(--primary2);
                border-radius: 15px;
                padding: 1rem 2rem;
            }
        }
    }
    // ================================================
    // Personalizar indicadores de navegación
    // ================================================
    .carousel-indicators{
        li{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid white;
            background-color: transparent;
            opacity: 1;
            &.active{
                background-color: var(--secondary);
            }
        }
    }
    @media screen and (max-width: 960px) {
        .item-carousel-home {
            img {
                height: 200px;
            }
            .container-info-banner{
                padding: 10px 10px 20px 10px;
                .button-banner-action{
                    padding: 0.4rem;
                    font-size: 0.8rem;
                }
            }
        }
        .carousel-indicators{
            li{
                width: 10px;
                height: 10px;
            }
        }
    }
}