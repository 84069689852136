.variables_form_module-layout{
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--text2);
    padding: 15px;
    // ============================================================
    // @INFO Item de variable
    // ============================================================
    .item_variable-layout{
        display: grid;
        grid-template-columns: repeat(3, 1fr) 2fr 30px;
        gap: 10px;
        align-items: center;
        svg{
            color: var(--text);
            font-size: 1.5rem;
            margin-top: 15px;
            &:hover{
                cursor: pointer;
            }
        }
        .range-input-variables{
            .container-inputs-range{
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}