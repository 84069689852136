.login_form-layout{
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-login {
        width: 350px;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: var(--background-login);
        -webkit-box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        padding: 1.5rem;
        border-radius: 16px;
        @media screen and (max-width: 960px) {
            width: 100%;
        }
        .btn-login {
            border-radius: 24px;
            padding: 0.6rem 1.5rem;
            text-transform: none;
            font-size: 1.05rem;
        }
        .login-text {
            margin-bottom: 10px;
            text-align: center;
        }
        .forgot-password-login {
            text-align: center;
            color: var(--text2);
            margin-top: -5px;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .create-account-text {
            text-align: center;
            font-weight: 600;
            color: var(--primary);
            &:hover {
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 960px){
        padding: 20px;
        width: 100%;
        z-index: 1;
        height: 90vh;
        align-items: flex-end;
        .form-login {
            -webkit-box-shadow: 0px 0px 30px 11px rgba(0, 250, 236, 0.29);
            box-shadow: 0px 0px 30px 11px rgba(0, 250, 236, 0.29);
        }
    }
}