.timer_component-layout{
    border-radius: 50%;
    box-shadow: var(--shadow);
    .container-timer-indicator{
        border-radius: 16px;
        background-color: var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
        &>p{
            font-weight: 0.9rem;
            color: white;
            font-weight: 600;
        }
        svg{
            color: white;
            font-size: 1.1rem;
        }
    }
    &.small{
        .container-with-progress{
            width: 90px;
            height: 90px;
        }
        .container-timer-indicator{
            padding: 4px 8px;
            gap: 2px;
            &>p{
                font-size: 0.7rem;
            }
            svg{
                font-size: 0.8rem;
            }
        }
    }
    &.normal{
        .container-with-progress{
            width: 150px;
            height: 150px;
        }
        .container-timer-indicator{
            padding: 4px 8px;
            gap: 3px;
            &>p{
                font-size: 0.9rem;
            }
            svg{
                font-size: 1rem;
            }
        }
    }
    &.large{
        .container-with-progress{
            width: 250px;
            height: 250px;
        }
        .container-timer-indicator{
            padding: 5px 10px;
            gap: 3px;
            &>p{
                font-size: 1.1rem;
            }
            svg{
                font-size: 1.4rem;
            }
        }
    }
}