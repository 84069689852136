.login_page-layout {
    display: flex;
    min-height: 100vh;
    position: relative;
    background-color: var(--background-login);
    .container-info {
        height: calc(100vh - 50px);
        width: 70vw;
        background-color: var(--primary);
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
        z-index: 1;
        position: relative;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-change-theme {
            position: absolute;
            top: 10px;
            right: 10px;
            svg {
                color: white;
                font-size: 2rem;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .icon-vlesim {
            position: absolute;
            top: 30px;
            left: 30px;
            height: 40px;
        }
        .icon-section {
            height: 100%;
            width: 100%;
        }
        .container-icons {
            position: absolute;
            bottom: 30px;
            left: 30px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .icon-menu {
                font-size: 1.7rem;
                color: white;
                svg {
                    path {
                        stroke: white;
                        fill: white;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .container-options {
            position: absolute;
            bottom: 30px;
            right: 30px;
            .button-options {
                background-color: var(--accent);
                border-radius: 16px;
                color: white;
                padding: 0.7rem 1.5rem;
                text-transform: none;
                font-size: 1.05rem;
            }
        }
    }
    @media screen and (max-width: 960px) {
        flex-direction: column;
        .container-info {
            width: 100%;
            position: fixed;
            z-index: 0;
            top: 0px;
            left: 0px;
            height: 65vh;
            .container-icons {
                display: none;
            }
            .button-options {
                display: none;
            }
            .icon-section {
                height: initial;
                width: 100%;
            }
            .icon-vlesim {
                height: 30px;
            }
            .btn-change-theme {
                position: fixed;
                z-index: 10;
            }
        }
    }
}