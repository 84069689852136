.switch-component {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    .custom-control-input:checked~.custom-control-label::before {
        border-color: var(--primary);
        background-color: var(--primary);
    }
    .custom-switch .custom-control-label::before {
        width: 2.1rem;
        left: -2.25rem;
    }
    .custom-switch .custom-control-input:checked~.custom-control-label::after {
        transform: translateX(1rem);
    }
    .custom-switch .custom-control-label::before {
        background-color: var(--gray-300);
        height: 1.1rem;
    }
    .custom-switch .custom-control-label::after {
        background-color: white;
        width: calc(1.1rem - 4px);
        height: calc(1.1rem - 4px);
        border-radius: 50%;
        cursor: pointer;
    }
    .custom-control-label::before {
        border-color: var(--gray-300);
        cursor: pointer;
    }
    &.small {
        .custom-switch .custom-control-label::before {
            width: 1.9rem;
            left: -2.25rem;
            border-radius: 0.45rem;
        }
        .custom-switch .custom-control-input:checked~.custom-control-label::after {
            transform: translateX(1rem);
        }
        .custom-switch .custom-control-label::before {
            height: 0.9rem;
        }
        .custom-switch .custom-control-label::after {
            width: calc(0.9rem - 4px);
            height: calc(0.9rem - 4px);
        }
    }
    &.large {
        .custom-switch .custom-control-label::before {
            width: 2.8rem;
            left: -2.25rem;
            border-radius: 0.75rem;
        }
        .custom-switch .custom-control-input:checked~.custom-control-label::after {
            transform: translateX(1.3rem);
        }
        .custom-switch .custom-control-label::before {
            height: 1.5rem;
        }
        .custom-switch .custom-control-label::after {
            width: calc(1.5rem - 4px);
            height: calc(1.5rem - 4px);
        }
    }
}
