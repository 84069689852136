.video_component-layout{
    .video-container-package{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &>iframe{
            width: 100%;
        }
    }
}