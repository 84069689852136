.notification_item-layout{
    display: grid;
    align-items: center;
    grid-template-columns: 40px calc(100% - 48px);
    gap: 8px;
    min-width: 270px;
    &:hover{
        cursor: pointer;
    }
    .text-notification{
        font-size: 0.7rem;
    }
    .icon-notification{
        svg{
            color: var(--accent);
            font-size: 2rem;
        }
        &.read{
            svg{
                color: var(--text2);
            }
        }
    }
    &>div{
        display: flex;
        flex-direction: column;
        gap: 1px;
        &>div{
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
            p{
                font-weight: 700;
            }
        }
    }
}