.header-layout {
    background-color: var(--background);
    // height: 105px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    // position: fixed;
    gap: 50px;
    top: 0px;
    left: 0px;
    z-index: var(--z2);
    padding: 15px;
    &>img {
        height: 60px;
        padding: 7px 0px;
        margin-left: 10%;
        align-self: flex-start;
        &:hover {
            cursor: pointer;
        }
    }
    .title-header {
        font-size: 1.8rem;
    }
    .container-icons-header {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 15px;
        .change-theme-icon-header {
            height: 2.2rem;
            width: 2.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--background-inverter);
            &:hover {
                cursor: pointer;
            }
            svg {
                color: var(--background);
                font-size: 1.4rem;
            }
        }
    }
    // ============================================
    // @INFO Icono para el header
    // ============================================
    .icon_header-layout {
        position: relative;
        &:hover {
            cursor: pointer;
            svg {
                color: var(--primary);
            }
        }
        svg {
            color: var(--text2);
            font-size: 1.7rem;
            path{
                stroke: var(--text2);
            }
        }
        .number-icon-header {
            position: absolute;
            right: -4px;
            top: -4px;
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 50%;
            background-color: var(--primary2);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.7rem;
            font-family: var(--font-title);
            color: white;
            font-weight: 400;
            margin: 0px;
            padding: 0px;
        }
    }
    @media screen and (max-width: 960px) {
        position: fixed;
        top: initial;
        right: initial;
        bottom: 0px;
        left: 0px;
        height: 70px;
        border-radius: 50px 50px 0px 0px;
        box-shadow: 0px -10px 50px rgba(0, 250, 236, 0.25);
        .title-header {
            display: none;
        }
        .input-search-header {
            display: none;
        }
        .container-icons-header {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            // flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0px;
            gap: 0px;
            .icon_header-layout {
                width: fit-content;
                margin: auto;
            }
            .change-theme-icon-header {
                margin: auto;
            }
        }
    }
}