.app_layout-layout {
    transition: all 0.2s ease;
    &>div:first-of-type {
        padding: 15px;
        transition: all 0.2s ease;
        float: left;
        width: 120px;
        height: 100vh;
    }
    &>div:nth-child(2) {
        overflow: hidden;
        transition: all 0.2s ease;
    }
    .main-app-layout {
        padding: 20px;
        height: calc(100vh - 70px);
        transition: all 0.2s ease;
        overflow-y: auto;
    }
    &.open-sidebar {
        transition: all 0.2s ease;
        &>div:first-of-type {
            width: 300px;
        }
    }
    @media screen and (max-width: 960px) {
        display: grid;
        grid-template-columns: 100%;
        &>div:first-of-type {
            padding: 0px;
            width: auto;
            height: auto;
        }
        &.open-sidebar {
            &>div:first-of-type {
                width: auto;
            }
        }
        .main-app-layout {
            padding: 10px;
        }
    }
}