.plans_page-layout{
    .plans_header--container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .plans_header--container-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
        .plans_header--container-cards{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            align-items: flex-end;
            background-color: var(--background2);
            border-radius: 15px;
            padding: 15px;
            .plans_header--container-card{
                background-color: transparent;
            }
        }
    }
}