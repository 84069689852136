.sidebar-layout {
    height: 100%;
    width: 100%;
    background-color: var(--primary);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 10px;
    align-items: center;
    transition: all 0.2s ease;
    position: relative;
    .container-image-sidebar {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 20px;
        .logo-vlesim-sidebar {
            height: 38px;
            &:hover{
                cursor: pointer;
            }
        }
        .icon-arrow-sidebar {
            color: white;
            margin-left: auto;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .menu-movil-sidebar {
        display: none;
    }
    .menu-profile-sidebar {
        width: 80%;
        margin-top: 40px;
        margin-bottom: 20px;
        .btn-menu-sidebar {
            // display: flex;
            // gap: 15px;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 15px;
            border-color: var(--accent);
            background-color: var(--primary);
            width: 100%;
            display: grid;
            grid-template-columns: 45px calc(95% - 55px) 10px;
            .img-avatar-sidebar {
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
            .content-text-person-sidebar {
                &>p {
                    margin: 0px;
                    padding: 0px;
                    font-family: var(--font-title);
                    color: white;
                    max-width: 90%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &>p:first-of-type {
                    font-size: 1rem;
                }
                &:nth-child(2) {
                    font-size: 0.8rem;
                    color: var(--text2);
                }
            }
            &.btn-success:not(:disabled):not(.disabled).active,
            &.btn-success:not(:disabled):not(.disabled):active,
            &.show>.btn-success.dropdown-toggle {
                background-color: var(--primary);
                border-color: var(--accent);
            }
            &.btn-success.focus,
            &.btn-success:focus {
                box-shadow: var(--shadow-button-primary);
            }
            &.btn-check:active,
            &.btn-success,
            &.btn-check:checked,
            &.btn-success,
            &.btn-success.active,
            &.btn-success:active,
            &.show>.btn-success.dropdown-toggle {
                background-color: var(--primary);
                border-color: var(--accent);
            }
        }
        &.white {
            .btn-menu-sidebar {
                .content-text-person-sidebar {
                    &>p {
                        color: black;
                    }
                    &:nth-child(2) {
                        color: black;
                    }
                }
                &.btn-success:not(:disabled):not(.disabled).active,
                &.btn-success:not(:disabled):not(.disabled):active,
                &.show>.btn-success.dropdown-toggle {
                    background-color: white;
                    border-color: var(--accent);
                }
                &.btn-success.focus,
                &.btn-success:focus {
                    box-shadow: var(--shadow-button-primary);
                }
                &.btn-check:active,
                &.btn-success,
                &.btn-check:checked,
                &.btn-success,
                &.btn-success.active,
                &.btn-success:active,
                &.show>.btn-success.dropdown-toggle {
                    background-color: white;
                    border-color: var(--accent);
                }
            }
        }
    }
    .container-icons-sidebar {
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
        margin-right: 20px;
        .item_sidebar-layout {
            display: flex;
            gap: 20px;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            background-color: var(--primary);
            padding: 20px 10px;
            padding-left: 10%;
            width: calc(100%);
            margin-left: 20px;
            align-items: center;
            position: relative;
            text-decoration: none;
            &:hover {
                cursor: pointer;
            }
            svg {
                font-size: 1.5rem;
                color: var(--text2);
                path {
                    stroke: var(--text2);
                }
            }
            p {
                font-family: var(--font-title);
                font-weight: 400;
                font-size: 0.9rem;
                color: var(--text2);
                margin: 0px;
                padding: 0px;
            }
            &.active {
                background-color: var(--background);
                svg {
                    color: var(--primary);
                    path {
                        stroke: var(--primary);
                    }
                }
                p {
                    color: var(--primary);
                    font-weight: 600;
                }
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    background-color: transparent;
                    bottom: -20px;
                    height: 20px;
                    right: 0px;
                    width: 25px;
                    border-top-right-radius: 30px;
                    box-shadow: 6px -5px 0px 0px var(--background);
                }
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    background-color: var(--primary);
                    top: -20px;
                    height: 20px;
                    right: 0px;
                    width: 25px;
                    border-bottom-right-radius: 30px;
                    box-shadow: 6px 5px 0px 0px var(--background);
                }
                &.dark-theme{
                    svg {
                        color: var(--accent);
                        path {
                            stroke: var(--accent);
                        }
                    }
                    p {
                        color: var(--accent);
                    }
                }
            }
            &.dark-theme{
                svg {
                    color: var(--text);
                    path {
                        stroke: var(--text);
                    }
                }
                p {
                    color: var(--text);
                }
            }
        }
    }
    &.colapse {
        width: 100px;
        transition: all 0.2s ease;
        .menu-profile-sidebar {
            .btn-menu-sidebar {
                gap: 0px;
                padding: 8px;
                .content-text-person-sidebar {
                    display: none;
                }
            }
        }
        .container-icons-sidebar {
            .item_sidebar-layout {
                padding-left: 18px;
                p {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 960px) {
        border-radius: 0px;
        padding: 15px;
        position: relative;
        .container-image-sidebar {
            margin-top: 0px;
            .icon-arrow-sidebar {
                display: none;
            }
            .logo-vlesim-sidebar {
                height: 30px;
                width: auto;
                align-self: flex-start;
                margin-top: 0px;
            }
        }
        .menu-movil-sidebar {
            position: absolute;
            right: 15px;
            top: 18px;
            display: block;
            z-index: 2;
            svg {
                color: white;
                font-size: 2rem;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .menu-profile-sidebar {
            display: none;
        }
        .container-icons-sidebar {
            transition: all 0.2s ease;
            position: fixed;
            z-index: 1000;
            top: 60px;
            right: 0px;
            width: 100vw;
            height: calc(100vh - 60px);
            background-color: rgba($color: #000000, $alpha: 0.3);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 0px;
            .item_sidebar-layout {
                background-color: var(--primary);
                margin: 2px 0px;
                width: calc(100% - 50px);
                border-radius: 0px;
                padding: 25px 10px;
                padding-left: 10%;
                p {
                    color: #969BA0;
                }
                svg {
                    color: #969BA0;
                }
                &.active {
                    background-color: var(--primary);
                    p {
                        color: var(--primary2);
                    }
                    svg {
                        color: var(--primary2);
                    }
                    &::after {
                        display: none;
                    }
                    &::before {
                        display: none;
                    }
                }
                &:last-of-type {
                    border-bottom-left-radius: 25px;
                }
            }
        }
        &.colapse {
            width: 100vw;
            .container-image-sidebar {
                margin-top: 0px;
            }
            .container-icons-sidebar {
                transition: all 0.2s ease;
                width: 0px;
                .item_sidebar-layout {
                    display: none;
                }
            }
        }
    }
}