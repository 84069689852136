.smart_factory_footer--layout{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &>img{
        max-width: 1000px;
        object-fit: cover;
    }
    @media screen and (max-width: 1000px) {
        &>img{
            // width: 100%;
            max-width: 100%;
        }
    }
}