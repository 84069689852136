.input_component-layout{
    width: 100%;
    .container-inputs_component-layout {
        position: relative;
        $size-icon: 1.4rem;
        width: 100%;
        .form-control {
            width: 100%;
            font-family: var(--font-text);
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem;
            color: var(--text);
            min-height: 45px;
            &:focus {
                color: var(--text);
                border-color: var(--primary);
                box-shadow: var(--shadow-button-primary);
                background-color: var(--background2);
            }
            border: none !important;
            border-radius: 8px !important;
            background-color: var(--background2);
            &.icon-spacing {
                padding-left: calc(#{$size-icon} + 0.7rem);
            }
        }
        .container_icon-input {
            position: absolute;
            font-size: $size-icon;
            width: $size-icon;
            left: 5px;
            color: var(--text2);
            fill: var(--text2);
            z-index: 1000;
        }
    }
}