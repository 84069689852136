.search_laboratories-layout{
    &>div:first-of-type{
        &>input{
            width: 300px;
            outline: none;
            border: none;
            border-radius: 8px;
            color: var(--text);
            background-color: var(--background-input);
            padding: 8px 12px;
            font-family: var(--font-text);
        }
        &>div{
            z-index: var(--z4);
            background-color: var(--background) !important;
            background: var(--background) !important;
            border-radius: 0px 0px 16px 16px !important;
        }
        .item-search-option{
            border-bottom: 1px solid var(--text2);
            max-width: 500px;
            &:last-of-type{
                border-radius: 0px 0px 16px 16px;
                border-bottom: none;
            }
        }
    }
    // =====================================================
    // Item de opción en el select
    // =====================================================
    .item_option_search-layout{
        display: grid;
        grid-template-columns: 50px 1fr 1.4rem;
        align-items: flex-start;
        gap: 8px;
        padding: 8px;
        background-color: var(--background);
        &:hover{
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
        }
        img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 12px;
        }
        .container-text-item{
            display: flex;
            flex-direction: column;
        }
        .link-icon{
            margin-left: auto;
            font-size: 1.4rem;
            color: var(--text2);
        }
    }
}