.source_module_dashboard-layout{
    width: 100%;
    .header-card{
        background-color: var(--primary3);
        padding: 20px;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &>p{
            color: var(--secondary);
            font-weight: 500;
            font-size: 1.125rem;
            &:first-of-type{
                color: white;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
    .container-body-card{
        border-radius: 0px 0px 10px 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }
}