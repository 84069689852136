.progress_bar-layout {
    width: 100%;
    .text-progress-container {
        display: flex;
        align-items: center;
        .progress-number-text {
            margin-left: auto;
            font-weight: bold;
        }
    }
    .container-progress-background {
        background-color: #C4C4C4;
        height: 10px;
        width: 100%;
        border-radius: 20px;
        position: relative;
        .progress-component {
            background: var(--primary);
            height: 100%;
            width: 0%;
            border-radius: 20px;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    &.as-inline {
        display: grid;
        width: 100%;
        grid-template-columns: calc(100% - 40px) 40px;
        align-items: center;
    }
}