.create_tool_page-layout{
    .container-form{
        background-color: var(--background);
        box-shadow: var(--shadow);
        border-radius: 30px;
        padding: 20px 30px;
        display: grid;
        gap: 14px;
        grid-template-columns: repeat(2, calc(50% - 7px));
        align-items: center;
        .container-images-form-general{
            grid-column: 1/3;
            display: grid;
            gap: 14px;
            grid-template-columns: repeat(2, calc(50% - 7px));
            align-items: center;
        }
        .container-checks-options{
            grid-column: 1/3;
            display: grid;
            grid-template-columns: repeat(4, calc(25% - 12px));
            gap: 16px;
        }
    }
}