.list_grid_control-layout{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 5px 10px 5px 0px;
    .grid-icon {
        padding: 5px;
        border-radius: 4px;
        &.active {
            background-color: rgba($color: #000000, $alpha: 0.4);
        }
        &:hover {
            cursor: pointer;
        }
        svg {
            font-size: 1.5rem;
            color: var(--text);
        }
    }
}