.stepper_component-layout {
    .container-icons-stepper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .icons_content_stepper-layout {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .content-icons {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
    .stepper_content_stepper-layout {}
    .icon_stepper-layout {
        flex: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        svg {
            color: var(--text2);
            font-size: 1.2rem;
            path {
                stroke: var(--text2);
            }
        }
        &.active {
            svg {
                color: var(--primary);
                path {
                    stroke: var(--primary);
                }
            }
        }
    }
    .content_stepper-layout {}
}