.notebook_dashboard-layout{
    display: grid;
    grid-template-columns: 250px 600px;
    gap: 16px;
    padding: 15px;
    border-radius: 0px 0px 30px 30px;
    background-color: var(--background);
    box-shadow: var(--shadow);
    .container-list-notebooks{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        padding: 8px;
    }
    .container-current-notebook{
        .frame-notebook{
            $width-frame: calc(100vw - 460px);
            width: $width-frame;
            height: calc(#{$width-frame} * 0.5625);
            border-radius: 20px;
            transition: all 0.2s ease;
            &.open-sidebar{
                $width-frame: calc(100vw - 640px);
                width: $width-frame;
                height: calc(#{$width-frame} * 0.5625);
                border-radius: 20px;
                transition: all 0.2s ease;
            }
        }
    }
    // ==================================================
    // @INFO Item de notebook
    // ==================================================
    .item_notebook-layout{
        background-color: var(--background);
        box-shadow: var(--shadow);
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        &:hover{
            cursor: pointer;
        }
        &.active{
            background-color: var(--primary2);
            &>p{
                color: white;
                font-weight: 700;
            }
        }
    }
}