.my_labs_page-layout{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .container-list-cards-preview {
        display: grid;
        grid-template-columns: repeat(3, calc(33% - 7px));
        align-items: center;
        justify-content: center;
        gap: 14px;
        &.list {
            grid-template-columns: 100%;
        }
    }
    @media screen and (max-width: 1600px){
        .container-list-cards-preview{
            grid-template-columns: repeat(2, calc(50% - 5px));
            padding-bottom: 40px;
            gap: 10px;
        }
    }
    @media screen and (max-width: 960px) {
        .container-list-cards-preview{
            grid-template-columns: 100%;
            padding-bottom: 40px;
            gap: 10px;
        }
    }
}