.image_component-layout {
    height: 100%;
    border-radius: 8px;
    box-shadow: var(--shadow);
    transition: 0.2s ease;
    &.cursor-pointer {
        cursor: pointer;
    }
}

.container-img-component-zoom {
    display: none;
    .container-img-body-zoom {
        display: none;
        .icon-close-img {
            display: none;
        }
    }
    &.make-zoom {
        display: flex;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.3);
        align-items: center;
        justify-content: center;
        .container-img-body-zoom {
            position: relative;
            display: initial;
            &>img {
                height: 80vh;
                border-radius: 8px;
                box-shadow: var(--shadow);
            }
            .icon-close-img {
                display: initial;
                position: absolute;
                top: -1.5rem;
                right: -0.8rem;
                font-size: 1.8rem;
                color: white;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}