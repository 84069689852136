.btn-custom {
    .spinner-loading-button {
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 0.5rem;
    }
    &.btn {
        &:focus {
            box-shadow: var(--shadow-button-primary);
        }
        text-transform: uppercase;
        padding: 0.3rem 1.5rem;
        font-size: 0.95rem;
        font-weight: 600;
        font-family: var(--font-title);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
    }
    &.btn-primary {
        background-color: var(--primary) !important;
        border-color: var(--primary) !important;
    }
    &.btn-accent {
        background-color: var(--accent) !important;
        border-color: var(--accent) !important;
    }
    &.btn-primary2 {
        background-color: var(--primary2) !important;
        border-color: var(--primary2) !important;
    }
    &.primary-inverter{
        background-color: var(--primary-inverter) !important;
        border-color: var(--primary-inverter) !important;
    }
    &.btn-primary:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus,
    &.show>.btn-primary.dropdown-toggle:focus {
        box-shadow: var(--shadow-button-primary) !important;
    }
    .container-icon-button {
        font-size: 1.5rem;
        margin-right: 8px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        fill: white !important;
        stroke: white !important;
        color: white !important;
    }
}