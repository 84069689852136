.header_landing_page-layout{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    background-color: var(--primary3);
    position: relative;
    width: 100vw;
    &>img{
        top: 30px;
        left: 30px;
        height: 40px;
    }
    .container-actions-header{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        .btn-change-theme{
            margin-left: auto;
            svg{
                color: white;
                font-size: 1.5rem;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 960px) {
        .container-menu-movil{
            margin-left: auto;
            svg{
                color: white;
                font-size: 1.3rem;
            }
        }
        .container-actions-header{
            flex-direction: column;
            position: fixed;
            right: 0px;
            top: 0px;
            width: 0vw;
            height: 100vh;
            z-index: var(--z3);
            align-items: center;
            background-color: var(--primary);
            transition: 0.2s all ease;
            overflow-x: hidden;
            button{
                background-color: transparent !important;
                border-color: transparent !important;
                color: white !important;
                font-weight: 400;
            }
            .btn-change-theme{
                margin: 0px;
            }
            .close-icon-menu-movil{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 50px;
                margin-top: 20px;
                padding-right: 20px;
                svg{
                    color: white;
                    font-size: 1.3rem;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            &.open{
                width: 70vw;
                transition: 0.2s all ease;
            }
        }
    }
}