.terms_and_conditions_page--layout{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  .terms_and_conditions--container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1000px;
    img {
      width: 250px;
      object-fit: cover;
    }
  }
}