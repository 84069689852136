.category_cataloger_card-layout{
    height: 370px;
    width: 100%;
    border-radius: 30px;
    box-shadow: var(--shadow);
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 0px;
    &>div:first-of-type{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: 100%;
        width: 100%;
        justify-content: center;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        position: relative;
        img{
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
        p, h2{
            z-index: 1;
        }
    }
    &>div:last-of-type{
        background-color: var(--primary2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding: 20px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        &>button{
            width: fit-content;
            align-self: flex-end;
            padding: 0.8rem 1.5rem;
        }
    }
}