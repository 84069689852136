.field_editor_component-layout {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    .container-cancel-save-text {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
    .edit-button-text {
        color: var(--primary2);
        &:hover {
            cursor: pointer;
        }
    }
    @media screen and (max-width: 960px) {}
}