.lab_in_progress-layout{
    background-color: var(--primary-inverter);
    padding: 3px 10px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: var(--shadow);
    p{
        color: white;
    }
}