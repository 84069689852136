.home_landing_page-layout{
    min-height: 100vh;
    width: 100%;
    padding: 0px 0px 30px 0px;
    .container-content-page{
        width: 100%;
        padding: 30px;
        .container-img-home-landing{
            margin-top: 20px;
            display: grid;
            grid-template-columns: calc(100% - 600px) 450px;
            gap: 20px;
            align-items: center;
            justify-content: center;
            &>img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .container-form-landing{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 25px;
            }
        }
        .container-benefits{
            width: 100%;
            border-radius: 25px;
            background-color: rgba($color: #000000, $alpha: 0.2);
            padding: 40px;
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 8px));
            gap: 16px;
            align-items: center;
            justify-content: center;
            &>div:first-of-type{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    // width: 100%;
                    height: 350px;
                    object-fit: cover;
                }
            }
            &>div:last-of-type{
                display: flex;
                flex-direction: column;
                gap: 8px;
                justify-content: center;
                align-items: center;
                .item-benefit{
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    &>div:first-of-type{
                        width: 3rem;
                        height: 3rem;
                        min-width: 3rem;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba($color: #0C9CF2, $alpha: 0.5);
                        p{
                            color: #0C9CF2;
                        }
                    }
                }
            }
        }
        .container-categories{
            width: 100%;
            border-radius: 0px 0px 50px 50px;
            background-color: transparent;
            padding: 30px;
    
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }
        .container-characteristics{
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 8px));
            gap: 16px;
            margin-top: 20px;
            ul, li{
                color: var(--text);
            }
            &>div{
                padding: 20px;
            }
            &>div:first-of-type{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            &>div:last-of-type{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                img{
                    height: 300px;
                }
            }
        }
        .container-plans{
            display: grid;
            grid-template-columns: repeat(3, calc(33.33% - 10.66px));
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 30px;
        }
    
        @media screen and (max-width: 1300px) {
            .container-benefits{
                grid-template-columns: 100%;
                &>div:first-of-type{
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .container-categories{
                grid-template-columns: repeat(2, 50%);
                row-gap: 35px;
            }
            .container-characteristics{
                grid-template-columns: 100%;
                &>div:last-of-type{
                    img{
                        height: auto;
                        width: 100%;
                    }
                }
            }
            .container-plans{
                grid-template-columns: 100%;
            }
        }
        @media screen and (max-width: 960px) {
            .container-categories{
                grid-template-columns: 100%;
                row-gap: 35px;
            }
        }
    }
    // ============ Personalizar banner ==================
    .banners-home-landing{
        margin-top: -5px;
    }
    .item-banner-home-landing{
        &>img{
            border-radius: 0px;
        }
        .container-info-banner{
            border-radius: 0px;
            align-items: center;
        }
    }
}