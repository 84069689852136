.img_profile_component-layout {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    .container {
        position: relative;
        width: 100%;
        height: 100%;
        &>label {
            position: absolute;
            left: 0px;
            top: 0px;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
            .container-icon-camera {
                display: none;
            }
            &:hover {
                cursor: pointer;
                .container-icon-camera {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    background-color: rgba($color: #000000, $alpha: 0.4);
                    svg {
                        color: white;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}