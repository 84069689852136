.smart_factory_new_experience--layout{
    display: grid;
    width: 100%;
    grid-template-columns: 0.6fr 0.4fr;
    gap: 30px;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    .smart_factory_new_experience--video{
        width: 650px;
        margin: auto;
        border: 1px solid white;
        border-radius: 16px;
        .video_component-layout {
            .video-container-package{
                iframe{
                    border-radius: 16px;
                }
            }
        }
    }
    .smart_factory_new_experience--text{
        color: white;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        &>h2{
            font-weight: bold;
        }
        .smart_factory_new_experience--button{
            background-color: #F8B133 !important;
            border-color: #F8B133 !important;
            border-radius: 8px;
            padding: 0.4rem 1.5rem;
            text-transform: none;
        }
    }
    @media screen and (max-width: 1250px) {
        grid-template-columns: 1fr;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (max-width: 750px) {
        .smart_factory_new_experience--video{
            width: 100%;
        }
    }
}