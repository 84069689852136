.render_dashboard_items--layout{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .render_dashboard_items--header{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .render_dashboard_items--items{
        display: flex;
        align-items: center;
        gap: 20px;
    }
}