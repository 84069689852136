.smart_factory_page--layout {
    background-color: #03133B;
    min-height: 100vh;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-repeat: no-repeat;
    background-position: center;
    .smart_factory_page--header{
        position: relative;
        z-index: 0;
        .smart_factory_page--nav-bar{
            z-index: 1;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    .banners-smart-factory{
        margin-top: -5px;
    }
    .item-banner-smart-factory{
        &>img{
            border-radius: 0px 0px 16px 16px;
            height: 500px;
        }
        .container-info-banner{
            border-radius: 0px 0px 16px 16px;
            justify-content: flex-end;
        }
    }
    .smart_factory_page--advantages-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        h1, p{
            color: white;
            margin: 0px;
            padding: 0px;
        }
        &>h1{
            font-weight: bold;
            text-transform: uppercase;
        }
        &>p{
            font-size: 1.3rem;
            max-width: 900px;
        }
    }
    .smart_factory_page--cards{
        padding: 0px 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .smart_factory_page--our-clients{
        margin: 0px 60px;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .smart_factory_page--plan{
        margin: 0px 60px;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    @media screen and (max-width: 1000px) {
        .smart_factory_page--cards{
            grid-template-columns: 1fr;
        }
        .smart_factory_page--advantages-text{
            padding: 0px 20px;
        }
        .smart_factory_page--cards{
            padding: 0px 20px;
        }
        .smart_factory_page--our-clients{
            margin: 30px 20px;
        }
        .smart_factory_page--plan{
            margin: 40px 20px 60px 20px;
        }
    }
}