.smart_factory_nav_bar--layout{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    height: 70px;
    width: 100vw;
    padding-right: 40px;
    padding-left: 0px;
    position: relative;
    .smart_factory_nav_bar--actions{
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
    .smart_factory_nav_bar--icon{
        position: relative;
        height: 100%;
        margin-right: auto;
        &>img:first-of-type{
            position: absolute;
            margin-left: 30px;
            margin-top: 20px;
        }
    }
    .smart_factory_nav_bar--social-networks{
        display: flex;
        align-items: center;
        gap: 10px;
        img{
            width: 20px;
            object-fit: cover;
        }
    }
    .smart_factory_nav_bar--link{
        color: white;
        text-decoration: none;
        margin-right: 20px;
    }
    .smart_factory_nav_bar--login{
        color: white;
        text-decoration: none;
        margin-right: 20px;
        border-radius: 16px;
        padding: 0.7rem 1rem;
        border: 1px solid white;
        background-color: rgba(0, 0, 0, 0.2);
    }
    @media screen and (max-width: 960px) {
        .container-menu-movil{
            margin-left: auto;
            svg{
                color: white;
                font-size: 1.3rem;
            }
        }
        .smart_factory_nav_bar--actions{
            flex-direction: column;
            position: fixed;
            right: 0px;
            top: 0px;
            width: 0vw;
            height: 100vh;
            z-index: var(--z2);
            align-items: center;
            justify-content: flex-start;
            background-color: var(--primary);
            transition: 0.2s all ease;
            overflow-x: hidden;
            button{
                background-color: transparent !important;
                border-color: transparent !important;
                color: white !important;
                font-weight: 400;
            }
            .close-icon-menu-movil{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 50px;
                margin-top: 20px;
                padding-right: 20px;
                svg{
                    color: white;
                    font-size: 1.3rem;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            &.open{
                width: 70vw;
                transition: 0.2s all ease;
            }
        }
    }
}