.payment_status_page-layout{
    .payment_status_page--container{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding-bottom: 50px;
    }
    .payment_status_page--status-card{
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: -70px;
        .payment_status--status-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            position: relative;
            font-size: 3.5rem;
            color: white;
            &::before{
                content: '';
                position: absolute;
                width: 90%;
                height: 90%;
                border-radius: 50%;
                top: 5%;
                left: 5%;
                background-color: transparent;
                border: 2px solid white;
            }
            &.success {
                background-color: var(--accent);
            }
            &.failed {
                background-color: var(--error);
            }
        }
        .payment_status--status-line{
            width: 250px;
            height: 10px;
            border-radius: 8px;
            &.success {
                background-color: var(--primary2);
            }
            &.failed {
                background-color: var(--text2);
            }
        }
    }
    .payment_status_page--content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 1000px;
        align-self: center;
        gap: 40px;
        &>div{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            gap: 15px;
            &:last-of-type{
                align-items: flex-start;
            }
        }
    }
    .payment_status_page--actions{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -28px;
        left: 0px;
        .payment_status_page--actions-home_button{
            padding: 0.8rem 1.2rem;
        }
    }
}