.contact_landing_page-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .container-form-image{
        margin-top: 100px;
        width: 90vw;
        padding: 30px;
        border-radius: 25px;
        background-color: var(--background-login);
        -webkit-box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 8px));
        gap: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        .icon-question{
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 1.8rem;
            color: #979797;
            &:hover{
                cursor: pointer;
            }
        }
        .container-image-contact{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                max-width: 550px;
                object-fit: cover;
            }
            .container-icons-social{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                svg{
                    font-size: 2.8rem;
                    color: #979797;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .input-contact-landing{
    }
    @media screen and (max-width: 1150px) {
        margin-bottom: 40px;
        .container-form-image{
            grid-template-columns: 100%;
        }
        .contact_landing--form{
            .container-general-fields{
                grid-template-columns: 100%;
            }
        }
    }
}