.reserve_laboratory_module-layout{
    max-height: 500px;
    $height-size: 500px;
    height: $height-size;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    width: 100%;
    .container-calendar-reserve{
        height: 100%;
    }
    .container-available-hours{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        min-height: 480px;
        overflow-y: auto;
        .list-available-hours{
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 8px));
            gap: 16px;
            justify-content: center;
            align-items: center;
            height: calc(#{$height-size} - 160px);
            overflow-y: auto;
        }
    }
    // ================================================
    // Item hora
    // ================================================
    .item_hour-layout{
        border-radius: 28px;
        padding: 0.4rem 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        .text-hour-option{
            color: var(--text);
            font-size: 1.2rem;
        }   
        &:hover{
            cursor: pointer;
        }
        &.active{
            background-color: var(--accent);
            .text-hour-option{
                color: white;
            }
        }
    }
}