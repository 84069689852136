.table_component-layout {
    td {
        height: 50px;
        color: var(--text);
    }
    th{
        color: var(--text);
    }
    .col-token{
        max-width: 100px;
    }
    .col-actions {
        width: 110px;
        .container-actions-table {
            display: flex;
            gap: 5px;
            align-items: center;
            .icon-table {
                font-size: 1.2rem;
                color: var(--text2);
                fill: var(--text2);
                stroke: var(--text2);
                margin-right: 8px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .container-image-table {
        height: auto;
        padding: 5px;
        width: 100px;
        .container-image {
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}