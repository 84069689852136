.pop_up-layout {
    // width: 360px;
    // min-width: 100px;
    max-width: 100% !important;
    min-height: 100px;
    padding: 35px 20px 25px 20px;
    background-color: var(--background) !important;
    color: var(--text);
    font-family: avr(--font-title);
    box-shadow: var(--shadow);
    border: none;
    border-radius: 25px;
    position: relative;
    &.hice-close {
        padding: 20px;
    }
    .icon-close-popup {
        position: absolute;
        top: 10px;
        right: 10px;
        svg {
            color: var(--text);
            font-size: 1.5rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

// .popover {
//     background-color: var(--background);
// }