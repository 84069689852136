.calendar_component-layout {
    background: var(--background-login);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 16px;
    .fc {
        .fc-toolbar,
        .fc-header-toolbar {
            align-items: center;
            .fc-toolbar-chunk {
                .fc-button-group {
                    .fc-prev-button, .fc-next-button{
                        font-size: 0.9rem;
                        padding: 0rem;
                        color: var(--text);
                        background-color: transparent !important;
                        border: none !important;
                    }
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                }
                .fc-toolbar-title {
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: var(--text);
                }
            }
        }
    }
    &.dayGridMonth{
        .fc {
            .fc-view-harness,
            .fc-view-harness-active {
                .fc-view {
                    .fc-scrollgrid-liquid {
                        border: none !important;
                        &::-webkit-scrollbar {
                            width: 0px !important;
                        }
                        thead {
                            .fc-col-header-cell-cushion {
                                font-size: 1rem !important;
                                color: var(--text2) !important;
                                text-decoration: none;
                            }
                            th {
                                border: none !important;
                            }
                            .fc-scroller {
                                &::-webkit-scrollbar {
                                    width: 0px !important;
                                }
                            }
                        }
                        tbody {
                            .fc-scrollgrid-sync-inner {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 0px;
                            }
                            .fc-daygrid-day-number {
                                font-size: 1.2rem !important;
                                color: var(--text) !important;
                                text-decoration: none;
                                &:hover {
                                    cursor: pointer !important;
                                }
                            }
                            .fc-daygrid-day-events {
                                display: none !important;
                            }
                            .fc-day {
                                border: none !important;
                            }
                            .fc-scroller-liquid-absolute {
                                &::-webkit-scrollbar {
                                    width: 0px !important;
                                }
                            }
                            .fc-bg-event {
                                background-color: var(--accent) !important;
                                width: 26px;
                                top: 3px;
                                left: calc(50% - 13px);
                                opacity: 0.6 !important;
                                border-radius: 15px;
                                .fc-event-title {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.timeGridDay{
        .fc {
            .fc-view-harness,
            .fc-view-harness-active {
                .fc-view {
                    .fc-scrollgrid-liquid {
                        border: none !important;
                        &::-webkit-scrollbar {
                            width: 0px !important;
                        }
                        thead {
                            .fc-col-header-cell-cushion {
                                font-size: 1rem !important;
                                color: var(--text2) !important;
                                text-decoration: none;
                            }
                            th {
                                border: none !important;
                            }
                            .fc-scroller {
                                &::-webkit-scrollbar {
                                    width: 0px !important;
                                }
                            }
                        }
                        tbody {
                            .fc-scrollgrid-sync-inner {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 0px;
                            }
                            .fc-daygrid-day-number {
                                font-size: 1.2rem !important;
                                color: var(--text) !important;
                                text-decoration: none;
                                &:hover {
                                    cursor: pointer !important;
                                }
                            }
                            .fc-daygrid-day-events {
                                display: none !important;
                            }
                            .fc-day {
                                border: none !important;
                            }
                            .fc-scroller-liquid-absolute {
                                &::-webkit-scrollbar {
                                    width: 0px !important;
                                }
                            }
                            .fc-bg-event {
                                background-color: var(--accent) !important;
                                opacity: 0.6 !important;
                                &:hover{
                                    cursor: pointer;
                                }
                                .fc-event-title {
                                    color: var(--text);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}