.saving_indicator-layout{
    display: flex;
    align-items: center;
    gap: 8px;
    .done-icon-indicator{
        font-size: 1.3rem;
        color: var(--accent);
    }
    .failed-icon-indicator{
        font-size: 1.3rem;
        color: firebrick;
    }
}