.code_editor_module_dashboard-layout{
    border-radius: 16px;
    padding: 15px;
    height: 100%;
    .container-list-files{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .container-code-editor_panel{
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 65px 1fr 60px;
        gap: 10px;
        height: 100%;
        .header-container-code-editor{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;
        }
        .code-editor-module{
            width: 100%;
            height: 100%;
            box-shadow: none;
            box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.51);
            -webkit-box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.51);
            -moz-box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.51);
            &>section{
                height: 100%;
            }
        }
    }
    .button-code-editor-module{
        font-size: 0.8125rem;
        padding: 0.3rem 1rem;
    }
    // ==================================================
    // Item de archivo
    // ==================================================
    .item_file-layout{
        padding: 7px;
        border-radius: 8px;
        box-shadow: var(--shadow);
        display: flex;
        align-items: center;
        border: 3px solid transparent;
        &:hover{
            cursor: pointer;
        }
        &.active{
            border-color: var(--primary2);
        }
        .container-actions{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-left: auto;
            svg{
                font-size: 1.1rem;
                color: var(--primary);
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}