.drop_down_component-layout{
  .drop-down-toggle{
    background-color: var(--primary-blanco);
    color: var(--text2);
    border-radius: 8px;
    border: 2px solid #EFECE9;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: var(--font-title);
    font-weight: 500;

    display: flex;
    align-items: center;
    gap: 8px;
    &:active{
      color: var(--text2) !important;
      background-color: var(--primary-blanco) !important;
      border: 2px solid #EFECE9 !important;
    }
    &:focus{
      box-shadow: none !important;
    }
    &::after{
      margin-left: auto;
    }
  }
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.show > .btn-primary.dropdown-toggle{
    background-color: var(--primary-blanco) !important;
    color: var(--text2) !important;
    border: 2px solid #EFECE9 !important;
  }
}
