.history_reserve_home-layout {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 30px;
    box-shadow: var(--shadow);
    background-color: var(--background-login);
    max-height: 600px;
    min-height: 600px;
    .header-card {
        margin-bottom: 10px;
        .header-card-title {
            font-weight: 700;
            font-size: 1.3rem;
        }
        .header-card-subtitle {
            font-size: 0.9rem;
            color: vat(--text2);
        }
    }
    .container-items {
        max-height: 500px;
        overflow-y: auto;
    }
    // =====================================================
    // Item del historial
    // =====================================================
    .history_reserve_item-layout {
        display: grid;
        grid-template-columns: 80px repeat(2, calc(50% - 90px)) 100px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-bottom: 1px solid var(--background3);
        .icon-item-reserve {
            border-radius: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 17px;
            background-color: rgba(2, 235, 72, 0.1);
            svg {
                font-size: 2.3rem;
                color: var(--accent) !important;
                stroke: var(--accent) !important;
                fill: var(--accent) !important;
            }
        }
        .container-text-reserve {
            .title-item-reserve {
                font-size: 1.1rem;
                font-weight: 500;
            }
            .subtitle-item-reserve {
                font-size: 0.9rem;
            }
        }
        .container-date-item {
            .title-date-reserve {
                font-size: 1.1rem;
                font-weight: 500;
            }
            .subtitle-date-reserve {
                font-size: 0.9rem;
            }
        }
        @media screen and (max-width: 1100px) {
            grid-template-columns: 50px repeat(2, 1fr) 45px;
            gap: 4px;
            .icon-item-reserve{
                padding: 10px;
                svg{
                    font-size: 1.6rem;
                }
            }
            .container-text-reserve{
                .title-item-reserve{
                    font-size: 1rem;
                    font-weight: 600;
                }
                .subtitle-item-reserve{
                    font-size: 0.7rem;
                }
            }
            .container-date-item{
                .title-date-reserve{
                    font-size: 0.9rem;
                    font-weight: 600;
                }
                .subtitle-date-reserve{
                    font-size: 0.8rem;
                }
            }
            .state-item-text{
                font-size: 0.9rem;
                font-weight: 600;
            }
        }
    }
}