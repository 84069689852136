.panel_component-layout{
    width: 100%;
    height: 100%;
    position: relative;
    .float-open-button-panel{
        border-radius: 0.2rem;
        width: 1.3rem;
        height: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary2);
        position: absolute;
        z-index: 1;
        top: 0px;
        box-shadow: var(--shadow);
        svg{
            color: white;
            font-size: 1.1rem;
        }
        &:hover{
            cursor: pointer;
        }
        &.left{
            left: 0px;
        }
        &.right{
            right: 0px;
        }
    }
    .container-panel_component{
        padding: 16px;
        padding-top: 40px;
        transition: all 0.2s ease;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        .icon-menu-panel{
            position: absolute;
            &:hover{
                cursor: pointer;
            }
            svg{
                font-size: 1.5rem;
                color: var(--text2);
            }
        }
        &.left{
            float: left;
            .icon-menu-panel{
                top: 10px;
                left: 10px;
            }
        }
        &.right{
            float: right;
            .icon-menu-panel{
                top: 10px;
                right: 10px;
            }
        }
    }
    .container-content_component{
        padding: 16px;
        overflow: hidden;
        transition: all 0.2s ease;
        overflow-y: auto;
    }
}