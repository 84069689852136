.code_authentication_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 120px);
    .code_authentication--container{
        width: 90%;
        padding: 30px;
        border-radius: 25px;
        background-color: var(--background-login);
        -webkit-box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        box-shadow: 50px -50px 45px 5px rgba(12, 156, 242, 0.31), -50px 50px 45px 5px rgba(0, 250, 236, 0.31);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        align-items: center;
        justify-content: center;
        .code_authentication_page--container-form{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            .code_authentication_page--form{
                max-width: 450px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 12px;
                .code_authentication_page--form-title{
                    font-size: 1.5rem;
                }
                .code_authentication_page--form-input{
                    width: 100%;
                }
            }
        }
        &>img{
            width: 100%;
        }
    }

    @media screen and (max-width: 980px) {
        .code_authentication--container{
            grid-template-columns: 100%;
            padding-top: 50px;
        }
    }
}