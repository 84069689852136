.card_category_home-layout {
    width: calc(100% - 15px);
    height: 270px;
    background-color: rgba(3, 19, 59, 0.6);
    margin: auto;
    margin-left: 10px;
    border-radius: 30px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container-image-card{
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            height: 220px;
            max-width: 100%;
            object-fit: cover;
            margin-top: -50px;
            margin-bottom: -20px;
        }
    }
    p {
        margin: 0px;
        padding: 0px;
        font-family: var(--font-text);
        font-weight: 400;
        font-size: 0.9rem;
        color: var(--text);
        line-height: 1rem;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &>p:first-of-type {
        font-size: 1.1rem;
        font-weight: 600;
    }
    .total-progress-container {
        display: grid;
        grid-template-columns: 70px calc(100% - 70px);
        align-items: center;
        .total-content-text {
            font-size: 0.55rem;
            margin: 0px;
        }
    }
    &:hover {
        cursor: pointer;
        box-shadow: var(--shadow);
    }
    @media screen and (max-width: 960px) {
        width: 100%;
    }
}

.slider-item-card-laboratory-category{
    width: calc(100% - 20px);
    height: 320px;
    display: flex;
    flex-direction: column;
    .card_category_home-layout{
        margin-top: auto;
    }
}