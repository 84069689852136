.chart_tool_dashboard-layout{
    .container-list-buttons{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .container-charts-component{
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 8px));
        gap: 16px;
    }
    // =========================================================
    // @INFO Item de boton para seleccionar grafica
    // =========================================================
    .item_graphic_button-layout{
        border-radius: 25px;
        border: 1px solid var(--text2);
        display: flex;
        align-items: center;
        justify-content: center;
        &>button{
            width: 100%;
        }
    }
}