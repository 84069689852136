.header_image_card-layout{
    border-radius: 25px;
    background-color: var(--background);
    box-shadow: var(--shadow);
    position: relative;
    padding-top: 120px;
    .header_image_card--image{
        border-radius: 25px 25px 0px 0px;
        width: 100%;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        padding-left: 30px;
        padding-top: 40px;
    }
    .header_image_card--container-children{
        z-index: 1;
        position: relative;
        padding: 20px;
        border-radius: 25px;
        width: 100%;
        background-color: var(--background);
    }
}