.modal_change_password-layout {
    .modal-content {
        background-color: var(--background);
    }
    .container-form-change-password {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        padding-bottom: 20px;
        .container-buttons {
            width: 300px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: center;
            &>button {
                flex: 1;
            }
        }
    }
}