.vr_container-layout{
    padding: 15px;
    border-radius: 30px;
    background-color: var(--background);
    box-shadow: var(--shadow);
    .frame-virtual-reality{
        $width-frame: 70vw;
        width: $width-frame;
        height: calc(#{$width-frame} * 0.5625);
        border-radius: 20px;
    }
    &.main{
        .frame-virtual-reality{
            $width-frame-main: calc(100vw - 200px);
            width: $width-frame-main;
            height: calc(#{$width-frame-main} * 0.5625);
        }
    }
    @media screen and (max-width: 960px){
        .frame-virtual-reality{
            $width-frame-movil: 100vw;
            width: $width-frame-movil;
            height: calc(#{$width-frame-movil} * 0.5625);
            border-radius: 20px;
        }
    }
}