.container_module_dashboard-layout{
    border-radius: 16px;
    padding: 15px;
    background-color: var(--background);
    box-shadow: var(--shadow);
    width: 100%;
    height: 100%;
    .header-container-module{
        border-radius: 25px 25px 0px 0px;
        border-bottom: 1px solid var(--text2);
        padding: 0px 15px 15px 15px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        width: 100%;
        cursor: move;
        .icon-drag-dashboard-item{
            color: var(--text);
            font-size: 1.3rem;
        }
        .icon-computer-menu{
            font-size: 2rem;
            color: var(--text);
            &:hover{
                cursor: pointer;
            }
        }
        .report-icon-tool{
            font-size: 1.4rem;
            color: var(--text);
            &:hover{
                cursor: pointer;
            }
        }
        .container-extra-items-container-module-dashboard{
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .container-children-module{
        height: calc(100% - 55px);
        overflow-y: auto;
        padding-right: 5px;
        margin-top: 8px;
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        &::-webkit-scrollbar-track {
            background: #F0F1F2;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: #AAA;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: var(--primary);
        }
    }
}