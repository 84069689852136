.preview_laboratory_card-layout {
    box-shadow: var(--shadow);
    background-color: var(--background);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow: hidden;
    &:hover{
        cursor: pointer;
    }
    &>img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        border-radius: 30px;
    }
    .color-text2 {
        color: var(--text2);
    }
    &>div:last-of-type {
        display: flex;
        gap: 10px;
        align-items: center;
        &>div {
            display: flex;
            margin-left: auto;
            gap: 10px;
            align-items: center;
            &>div:last-of-type{
                display: flex;
                align-items: center;
                gap: 5px;
                &>svg {
                    font-size: 1.5rem;
                    color: var(--text2);
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .btn-get-lab{
        padding: 0.5rem 0.6rem;
    }
    &.list {
        flex-direction: row;
        &>img {
            width: 300px;
            height: 150px;
        }
        &>div:last-of-type {
            margin-left: auto;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }
    .icon-star-rating{
        font-size: 1.3rem;
    }
    @media screen and (max-width: 960px) {
        padding: 10px;
        &>img{
            height: 160px;
            border-radius: 10px;
        }
        .title-card{
            font-size: 1.1rem;
        }
        .text-card{
            font-size: 1rem;
        }
        .btn-get-lab{
            padding: 0.5rem 0.4rem;
            font-size: 0.8rem;
            min-width: 80px;
            .container-icon-button{
                font-size: 1.1rem;
                margin-right: 2px;
            }
        }
        .icon-star-rating{
            font-size: 0.9rem;
        }
        &>div:last-of-type{
            &>div:first-of-type{
                flex-direction: row;
                &>div:first-of-type{
                    min-width: 70px;
                }
                &>:last-of-type{
                    flex-direction: column;
                    gap: 4px;
                    &>svg{
                        font-size: 1rem;
                    }
                }
            }
        }
        &.list {
            flex-direction: row;
            &>img {
                width: 150px;
                height: 100px;
            }
        }
    }
}