.laboratory_preview_page-layout {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    gap: 16px;
    .card-info-laboratory-section {
        &:nth-child(2){
            grid-column: 1/3;
        }
        padding: 25px;
        border-radius: 30px;
        box-shadow: var(--shadow);
        display: flex;
        flex-direction: column;
        gap: 10px;
        .header-card-lab {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            .container-actions-header-card-lab{
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                margin-left: auto;
            }
        }
        .container-info-lab {
            display: grid;
            grid-template-columns: calc(70% - 20px) 30%;
            gap: 20px;
            &>div:first-of-type {
                display: flex;
                flex-direction: column;
                gap: 10px;
                &>img {
                    width: 100%;
                    height: 500px;
                    border-radius: 15px;
                    object-fit: cover;
                }
            }
            &>:last-of-type {
                background-color: var(--background2);
                border-radius: 25px;
                padding: 15px;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
    .star-rating-card{
        font-size: 1.3rem;
    }
    .img-scheme-principal{
        border-radius: 15px;
        object-fit: cover;
        height: 200px;
    }
    @media screen and (max-width: 960px) {
        grid-template-columns: 100%;
        padding-bottom: 50px;
        &>div:first-of-type{
            grid-column: 1/2;
        }
        &>div {
            padding: 15px;
            .header-card-lab{
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                .hrader-card-lab-title{
                    font-size: 1.1rem;
                }
                .container-actions-header-card-lab{
                    gap: 2px;
                    justify-content: flex-start;
                    width: 100%;
                    margin-left: 0px;
                    &>button{
                        font-size: 0.6rem;
                        padding: 0.4rem 0.3rem;
                        svg{
                            font-size: 0.7rem;
                        }
                    }
                    &>div:last-of-type{
                        margin-left: auto;
                    }
                }
            }
            .container-info-lab{
                grid-template-columns: 100%;
                &>div:first-of-type {
                    &>img {
                        height: 200px;
                    }
                }
            }
        }
        .star-rating-card{
            font-size: 0.8rem;
            margin-left: auto;
        }
        .img-scheme-principal{
            height: 200px;
        }
    }
}